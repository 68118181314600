import api from "Api";

export default {
  namespaced: true,

  state: {
    navigation: {},
    loading: false,
    error: null
  },

  getters: {
    mainNavigation(state) {
      return (state.navigation && state.navigation.navigation) || [];
    },
    accountNavigation(state) {
      return (state.navigation && state.navigation.account) || [];
    },
    helpNavigation(state) {
      return (state.navigation && state.navigation.help) || [];
    },
    mcaNavigation(state) {
      return (state.navigation && state.navigation.MCA) || [];
    }
  },

  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setNavigation(state, payload) {
      state.navigation = payload;
    }
  },

  actions: {
    async fetchNavigation({ commit }) {
      try {
        commit("setLoading", true);
        commit("setError", null);

        const response = await api.get("/api/navigation");
        commit("setNavigation", response.body);
      } catch (err) {
        commit("setError", err);
      } finally {
        commit("setLoading", false);
      }
    }
  }
};
