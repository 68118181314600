/* eslint-disable import/order */
import { initSentry } from "js/global/sentry";
import i18nSetup from "./i18n-setup";

/* Store */
/* Vue components */
import AcceptTermsForm from "Components/AcceptTermsForm.vue";
import AmbassadorUserList from "Components/virtual-auditions/AmbassadorUserList.vue";
import Alert from "Components/AlertMessage.vue";
import ChangePassword from "Components/ChangePassword.vue";
import CoverCarousel from "Components/organization-landing/CoverCarousel.vue";
import CreateStudent from "Components/sign-up/CreateStudent.vue";
import SocialLoader from "Components/organization-landing/SocialLoader.vue";
import Favorite from "Components/organization-landing/Favorite.vue";
import ForgotPassword from "Components/ForgotPassword.vue";
import Modal from "Components/common/Modal.vue";
import Navigation from "Components/organization-landing/Navigation.vue";
import Overview from "Components/organization-landing/Overview.vue";
import ProgramGuidelines from "Components/organization-landing/ProgramGuidelines.vue";
import ProgramSelect from "Components/organization-landing/ProgramSelect.vue";
import Resources from "Components/organization-landing/Resources.vue";
import SignUpApplicant from "Components/sign-up/SignUpApplicant.vue";
import SignUpParentTeacher from "Components/sign-up/SignUpParentTeacher.vue";
import UserLogin from "Components/UserLogin.vue";
import UserLogout from "Components/UserLogout.vue";
import UserLocation from "Components/UserLocation.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import VueTippy, { TippyComponent } from "vue-tippy";
import Vue from "vue";
import Vuex from "vuex";
import ApiPlugin from "Components/plugins/api";
import CsrfTokenPlugin from "Components/plugins/csrf-token";
import i18nextPlugin from "Components/plugins/i18next";
import StoreOptions from "./store";

const init = async function main() {
  await i18nSetup();

  Vue.use(i18nextPlugin);
  Vue.use(CsrfTokenPlugin);

  Vue.use(ApiPlugin);
  Vue.Api.setCSRFToken(Vue.CsrfToken.token);

  Vue.use(Vuex);
  const store = new Vuex.Store(StoreOptions);

  Vue.component("validation-provider", ValidationProvider);
  Vue.component("validation-observer", ValidationObserver);

  Vue.use(VueTippy);
  Vue.component("tippy", TippyComponent);

  /* eslint-disable no-new */
  new Vue({
    el: "#vue-app",
    store,
    components: {
      AcceptTermsForm,
      AmbassadorUserList,
      ChangePassword,
      CoverCarousel,
      CreateStudent,
      SocialLoader,
      Favorite,
      ForgotPassword,
      Modal,
      Navigation,
      Overview,
      ProgramGuidelines,
      ProgramSelect,
      Resources,
      SignUpApplicant,
      SignUpParentTeacher,
      UserLocation,
      UserLogin,
      UserLogout
    },
    mounted() {
      // hide spinner
      document.getElementById("ac-ldr").style.display = "none";
      // set aria busy to false
      document.getElementsByTagName("body")[0].setAttribute("aria-busy", "false");
    }
  });

  new Vue({
    el: "#alert-growl-root",
    store,
    components: { AlertMessage: Alert }
  });
};

if (module.hot) {
  module.hot.accept();
}

if (process.env.SENTRY_DSN_PUBLIC) {
  initSentry(process.env.SENTRY_DSN_PUBLIC);
}

init();
